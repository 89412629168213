import { ReactNode } from "react";
import * as React from "react";
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";

import { linkResolver } from "./src/utils/link-resolver";

import "./src/styles/global.css";

export const wrapRootElement: React.FC<
  React.PropsWithChildren<{ element: ReactNode }>
> = ({ element }) => {
  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      {element}
    </PrismicProvider>
  );
};
