/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
const { appConfiguration } = require("../../app-configuration");
// @ts-ignore
export const linkResolver = (doc) => {
  switch (doc.type) {
    case "privacy": {
      return doc.lang === appConfiguration.defaultLanguage
        ? "/privacy"
        : `/${doc.lang}/privacy`;
    }
    case "terms": {
      return doc.lang === appConfiguration.defaultLanguage
        ? "/terms"
        : `/${doc.lang}/terms`;
    }
    case "impressum": {
      return doc.lang === appConfiguration.defaultLanguage
        ? "/impressum"
        : `/${doc.lang}/impressum`;
    }
    default:
      return doc.lang === appConfiguration.defaultLanguage
        ? "/"
        : `/${doc.lang}/`;
  }
};
